import MDBox from "components/MDBox";
import * as React from "react";
import { useEffect, useContext, useState } from "react";
import "./tooltip.css";
import { addDoc, collection, Timestamp } from "firebase/firestore";
// import PropTypes from "prop-types";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import ListAltIcon from "@mui/icons-material/ListAlt";
import DoneIcon from "@mui/icons-material/Done";
import {
  Snackbar,
  Avatar,
  Card,
  Dialog,
  Step,
  // StepLabel,
  IconButton,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import canon from "assets/images/logos/canon.png";
import elekta from "assets/images/logos/elekta.png";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Unstable_Grid2";
import sabah from "assets/images/flags/Flag_of_Sabah.svg.png";
import selangor from "assets/images/flags/Flag_of_Selangor.svg.png";
import kedah from "assets/images/flags/2560px-Flag_of_Kedah.svg.png";
import wp from "assets/images/flags/Flag_of_the_Federal_Territories_of_Malaysia.svg.png";
import perak from "assets/images/flags/2560px-Flag_of_Perak.svg.png";
import ns from "assets/images/flags/Flag_of_Negeri_Sembilan.svg.png";
import melaka from "assets/images/flags/Flag_of_Malacca.svg.png";
import swk from "assets/images/flags/2560px-Flag_of_Sarawak.svg.png";
import penang from "assets/images/flags/Flag_of_Penang_(Malaysia).svg.png";
import kel from "assets/images/flags/Flag_of_Kelantan.png";
import johor from "assets/images/flags/Flag_of_Johor.svg.png";
import perlis from "assets/images/flags/2560px-Flag_of_Perlis.svg.png";
import pahang from "assets/images/flags/Flag_of_Pahang.png";
import tgn from "assets/images/flags/Flag_of_Terengganu.svg.png";
import siemens from "assets/images/logos/siemens.png";
import ge from "assets/images/logos/ge.png";
import philips from "assets/images/logos/philips.png";
import shimadzu from "assets/images/logos/shimadzu.png";
import { UserAuth } from "context/AuthContext";
import { UserContext } from "context/UserContext";
import { useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
// import SettingsIcon from "@mui/icons-material/Settings";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { NewMDInput, NewInputLabel, NewStepLabel, NewStepper } from "stylizedMUIComponent";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";
import { db } from "../../firebase-config";

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: theme.palette.success.main,
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { icon, active, completed, className } = props;

  const icons = {
    1: <DoneIcon />,
    2: <DoneIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

// ColorlibStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
//   /**
//    * The label displayed in the step icon.
//    */
//   icon: PropTypes.node,
// };

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

// const steps = ["Select campaign settings", "Create an ad group", "Create an ad"];

function Dashboard() {
  const usersCollectionRef = collection(db, "machines");
  const stepsAffiliation = ["Your details", "Your affiliation details", "Review"];
  const stepsMachineReg = ["Machine overview", "Machine details", "Review"];
  const [loading, setLoading] = useState(false);
  const [newIcNum, setNewIcNum] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [insertNum, setInsertNum] = useState("");
  const [housingNum, setHousingNum] = useState("");
  const [insertNum2, setInsertNum2] = useState("");
  const [housingNum2, setHousingNum2] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [assetNum, setAssetNum] = useState("");
  const [modalityTypes, setModalityTypes] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const isStepOptional = (step) => step === 1;
  let src;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const [newCustomerList, setNewCustomersList] = useState([]);
  const [hasTwoTubes, setHasTwoTubes] = useState(false);
  const [dialogAffiliationOpen, setDialogAffiliationOpen] = useState(false);
  const [dialogRegisterOpen, setDialogRegisterOpen] = useState(false);
  const { negeriId } = useSelector((state) => state.idDelete);
  const { machines, customers } = useContext(UserContext);
  const [maxKw, setMaxKw] = useState("");
  const [maxKvP, setMaxKvP] = useState("");
  const [maxMA, setMaxMA] = useState("");
  const { user } = UserAuth();
  // console.log(user);

  const customerDataFirebase = customers.filter((e) => e.email === user?.email);

  // console.log(customerDataFirebase);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const { data } = await fetch(
          `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/retrieveCustomer`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: user?.email,
            }),
          }
        ).then((r) => r.json());
        setNewCustomersList(data);
      } catch (e) {
        console.log(e.message);
      }
    };

    if (user?.email) {
      fetchCustomers();
    }
  }, [user?.email]);

  useEffect(() => {
    document.title = "Fastlane Dynamic SelfCare - Home";
  }, []);

  const { openSnackBar } = useSelector((state) => state.delete);

  const position = {
    vertical: "top",
    horizontal: "center",
  };

  const { vertical, horizontal } = position;

  const [error, setError] = useState("");

  const onChangeIcNum = (e) => {
    const newValue = e.target.value;

    if (newValue.match(/[^0-9]/)) {
      setError("Insert Number Only!");
    } else if (!newValue.match(/[^0-9]/) && newValue.length > 0 && newValue.length < 12) {
      setError("IC number must have 12 digits");
      setNewIcNum(newValue);
    } else if (!newValue.match(/[^0-9]/) && newValue.length === 0) {
      setError("");
      setNewIcNum(newValue);
    } else if (!newValue.match(/[^0-9]/) && newValue.length === 12 && newValue % 2 === 0) {
      setError("");
      setNewIcNum(newValue);
    } else if (!newValue.match(/[^0-9]/) && newValue.length === 12 && newValue % 2 !== 0) {
      setError("");
      setNewIcNum(newValue);
    }
  };

  const updateCustomer = async () => {
    setLoading(true);
    try {
      const { customer } = await fetch(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/updateCustomer`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customerId: customerDataFirebase[0]?.stripeId,
            name,
            icNum: newIcNum,
            approval: "awaiting",
            phone: phoneNum,
          }),
        }
      ).then((res) => res.json());
      setLoading(false);
      setDialogAffiliationOpen(false);
      setNewCustomersList([customer]);
      console.log(customer);
    } catch (e) {
      setLoading(false);
      setDialogAffiliationOpen(false);
      console.log(e.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await addDoc(usersCollectionRef, {
        modalityTypes,
        insertNum,
        timeStampReg: Timestamp.fromDate(new Date()),
      });
      setLoading(false);
      setDialogRegisterOpen(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const height = { height: 44 };

  const modalityTypesArr = [
    "Please Select",
    "General X-ray",
    "Mobile X-ray",
    "Fluoroscopy",
    "Mobile Fluoroscopy",
    "Computed Tomography (CT) Scanner",
    "Orthopantomogram (OPG)",
    "Cone Beam Computed Tomography (CBCT)",
  ];

  const brands = ["Please Select", "Canon", "Siemens", "GE", "Philips", "Shimadzu", "Elekta"];

  return (
    <DashboardLayout>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackBar}
        message={negeriId}
        sx={{ width: "380px" }}
      >
        <MDBox display="flex" flexDirection="row">
          <Avatar
            alt="huhu"
            src={(() => {
              if (negeriId === "Sabah") {
                return sabah;
              }

              if (negeriId === "Kedah") {
                return kedah;
              }

              if (
                negeriId === "Kuala Lumpur" ||
                negeriId === "Putrajaya" ||
                negeriId === "Labuan"
              ) {
                return wp;
              }

              if (negeriId === "Negeri Sembilan") {
                return ns;
              }

              if (negeriId === "Melaka") {
                return melaka;
              }

              if (negeriId === "Sarawak") {
                return swk;
              }

              if (negeriId === "Perak") {
                return perak;
              }

              if (negeriId === "Pulau Pinang") {
                return penang;
              }

              if (negeriId === "Kelantan") {
                return kel;
              }

              if (negeriId === "Johor") {
                return johor;
              }

              if (negeriId === "Perlis") {
                return perlis;
              }

              if (negeriId === "Pahang") {
                return pahang;
              }

              if (negeriId === "Trengganu") {
                return tgn;
              }

              return selangor;
            })()}
          />
          <MDBox sx={{ marginLeft: "10px" }}>
            <MDTypography variant="h6">
              {(() => {
                if (negeriId !== "Trengganu") {
                  return negeriId;
                }

                return "Terengganu";
              })()}
            </MDTypography>
            <MDTypography variant="body2" sx={{ fontSize: "0.8rem" }}>
              Mesin penyinaran perubatan berdaftar :{" "}
              {negeriId !== "Trengganu"
                ? machines.filter((e) => e.negeri === negeriId).length
                : machines.filter((e) => e.negeri === "Terengganu").length}
            </MDTypography>
            <MDTypography variant="body2" sx={{ fontSize: "0.8rem" }}>
              Kerajaan :{" "}
              {negeriId !== "Trengganu"
                ? machines.filter((e) => e.negeri === negeriId && e.sektor === "Kerajaan").length
                : machines.filter((e) => e.negeri === "Terengganu" && e.sektor === "Kerajaan")
                    .length}
            </MDTypography>
            <MDTypography variant="body2" sx={{ fontSize: "0.8rem" }}>
              Swasta :{" "}
              {negeriId !== "Trengganu"
                ? machines.filter((e) => e.negeri === negeriId && e.sektor === "Swasta").length
                : machines.filter((e) => e.negeri === "Terengganu" && e.sektor === "Swasta").length}
            </MDTypography>
            <MDTypography variant="body2" sx={{ fontSize: "0.8rem" }}>
              Peratus tamat tempoh sah lesen: 12%
            </MDTypography>
          </MDBox>
        </MDBox>
      </Snackbar>
      <OnlineStatusSnackbar />
      <DashboardNavbar />
      <Dialog maxWidth="lg" fullWidth open={dialogAffiliationOpen}>
        <Card sx={{ width: "100%", px: 3, py: 3 }}>
          <MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "start",
              }}
            >
              <IconButton
                sx={{ marginBottom: 3 }}
                color="error"
                onClick={() => setDialogAffiliationOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </MDBox>

            <NewStepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
              {stepsAffiliation.map((label, index) => {
                const stepProps = {};
                // const labelProps = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = <MDTypography variant="caption">Optional</MDTypography>;
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label}>
                    <NewStepLabel StepIconComponent={ColorlibStepIcon}>{label}</NewStepLabel>
                  </Step>
                );
              })}
            </NewStepper>
            {(() => {
              switch (activeStep) {
                case 0:
                  return (
                    <>
                      <Grid container columnSpacing={2} rowSpacing={2} paddingY={3}>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="IC number"
                            onChange={onChangeIcNum}
                            value={newIcNum}
                            inputProps={{ maxLength: 12 }}
                            helperText={error}
                            error={!!error}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Name as per IC"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Phone number"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                      </Grid>

                      <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <MDBox sx={{ flex: "1 1 auto" }} />
                        {isStepOptional(activeStep) && (
                          <MDButton color="warning" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                          </MDButton>
                        )}
                        <MDButton
                          disabled={
                            (error !== "" && error.length !== 0) || newIcNum === "" || name === ""
                          }
                          color="info"
                          onClick={handleNext}
                        >
                          Next
                        </MDButton>
                      </MDBox>
                    </>
                  );

                case 1:
                  return (
                    <>
                      <Grid container columnSpacing={2} rowSpacing={2} paddingY={3}>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="IC number of license holder"
                            onChange={onChangeIcNum}
                            value={newIcNum}
                            inputProps={{ maxLength: 12 }}
                            helperText={error}
                            error={!!error}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Name of license holder"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Name of clinic"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Address of clinic"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="IC number of supervisor"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Name of supervisor"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="IC number of Radiation Protection Officer"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Name of Radiation Protection Officer"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="License number"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Reference in Ministry of Health"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            type="date"
                            variant="outlined"
                            label="Validity due date"
                            onChange={(e) => setPhoneNum(e.target.value)}
                            value={phoneNum}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>

                      <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <MDButton
                          variant="outlined"
                          color="error"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </MDButton>
                        <MDBox sx={{ flex: "1 1 auto" }} />
                        {/* {isStepOptional(activeStep) && (
                        <MDButton color="warning" onClick={handleSkip} sx={{ mr: 1 }}>
                          Skip
                        </MDButton>
                      )} */}
                        <MDButton color="info" onClick={handleNext}>
                          Next
                        </MDButton>
                      </MDBox>
                    </>
                  );

                default:
                  return (
                    <>
                      <Grid container columnSpacing={2} rowSpacing={2} paddingY={3}>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body2">Name : {name}</MDTypography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body2">IC number : {newIcNum}</MDTypography>
                        </Grid>
                      </Grid>
                      <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <MDButton
                          variant="outlined"
                          color="error"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </MDButton>
                        <MDBox sx={{ flex: "1 1 auto" }} />
                        <MDButton
                          endIcon={
                            loading ? (
                              <CircularProgress disableShrink color="inherit" size="1rem" />
                            ) : null
                          }
                          color="success"
                          onClick={updateCustomer}
                          disabled={loading}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </>
                  );
              }
            })()}
          </MDBox>
        </Card>
      </Dialog>
      <Dialog maxWidth="lg" fullWidth open={dialogRegisterOpen}>
        <Card sx={{ width: "100%", px: 3, py: 3 }}>
          <MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "start",
              }}
            >
              <IconButton color="error" onClick={() => setDialogRegisterOpen(false)}>
                <CloseIcon />
              </IconButton>
            </MDBox>

            <NewStepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {stepsMachineReg.map((label) => (
                <Step key={label}>
                  <NewStepLabel StepIconComponent={ColorlibStepIcon}>{label}</NewStepLabel>
                </Step>
              ))}
            </NewStepper>
            {(() => {
              switch (activeStep) {
                case 0:
                  return (
                    <>
                      <Grid container columnSpacing={2} rowSpacing={2} paddingY={3}>
                        <Grid xs={12} md={6}>
                          <FormControl required fullWidth>
                            <NewInputLabel id="job-select-label">Machine type</NewInputLabel>
                            <Select
                              labelId="job-select-label"
                              id="job-select"
                              value={modalityTypes}
                              label="Machine type"
                              onChange={(e) => setModalityTypes(e.target.value)}
                              style={height}
                            >
                              {modalityTypesArr.map((e) => {
                                if (e !== "Please Select") {
                                  return (
                                    <MenuItem key={e} value={e}>
                                      {e}
                                    </MenuItem>
                                  );
                                }

                                return (
                                  <MenuItem key="0" value="">
                                    <em>Please Select</em>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={hasTwoTubes}
                                onChange={() => {
                                  setHasTwoTubes(!hasTwoTubes);
                                  setInsertNum2("");
                                  setHousingNum2("");
                                }}
                              />
                            }
                            label="Has two X-ray tubes"
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Tube (housing) number"
                            onChange={(e) => setHousingNum(e.target.value)}
                            value={housingNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            variant="outlined"
                            label="Tube (insert) number"
                            onChange={(e) => setInsertNum(e.target.value)}
                            value={insertNum}
                          />
                        </Grid>
                        {hasTwoTubes && (
                          <>
                            <Grid xs={12} md={6}>
                              <NewMDInput
                                sx={{ width: "100%" }}
                                variant="outlined"
                                label="Tube (housing) number 2"
                                onChange={(e) => setHousingNum2(e.target.value)}
                                value={housingNum2}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <NewMDInput
                                variant="outlined"
                                label="Tube (insert) number 2"
                                onChange={(e) => setInsertNum2(e.target.value)}
                                value={insertNum2}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <MDBox sx={{ flex: "1 1 auto" }} />
                        {/* {isStepOptional(activeStep) && (
                        <MDButton color="warning" onClick={handleSkip} sx={{ mr: 1 }}>
                          Skip
                        </MDButton>
                      )} */}
                        <MDButton
                          disabled={
                            modalityTypes === "" ||
                            insertNum === "" ||
                            housingNum === "" ||
                            (hasTwoTubes && (insertNum2 === "" || housingNum2 === ""))
                          }
                          color="info"
                          onClick={handleNext}
                        >
                          Next
                        </MDButton>
                      </MDBox>
                    </>
                  );

                case 1:
                  return (
                    <>
                      <Grid container columnSpacing={2} rowSpacing={2} paddingY={3}>
                        <Grid xs={12} md={6}>
                          <FormControl required fullWidth>
                            <NewInputLabel id="job-select-label">Brand</NewInputLabel>
                            <Select
                              labelId="job-select-label"
                              id="job-select"
                              value={brand}
                              label="Brand"
                              onChange={(e) => setBrand(e.target.value)}
                              style={height}
                            >
                              {brands.map((e) => {
                                if (e !== "Please Select") {
                                  // let src;
                                  switch (e) {
                                    case "Canon":
                                      src = canon;
                                      break;

                                    case "Siemens":
                                      src = siemens;
                                      break;

                                    case "GE":
                                      src = ge;
                                      break;

                                    case "Philips":
                                      src = philips;
                                      break;

                                    case "Shimadzu":
                                      src = shimadzu;
                                      break;

                                    case "Elekta":
                                      src = elekta;
                                      break;

                                    default:
                                      src = undefined;
                                  }
                                  return (
                                    <MenuItem key={e} value={e}>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <ListItemIcon sx={{ mr: "5px" }}>
                                          <img width="30px" src={src} alt={e} />
                                        </ListItemIcon>
                                        {e}
                                      </div>
                                    </MenuItem>
                                  );
                                }

                                return (
                                  <MenuItem key="0" value="">
                                    <em>Please Select</em>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Model"
                            onChange={(e) => setModel(e.target.value)}
                            value={model}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: "100%" }}
                            type="date"
                            variant="outlined"
                            label="Date of installation"
                            onChange={(e) => setInstallationDate(e.target.value)}
                            value={installationDate}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Asset number"
                            onChange={(e) => setAssetNum(e.target.value)}
                            value={assetNum}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Maximum kW"
                            onChange={(e) => setMaxKw(e.target.value)}
                            value={maxKw}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Maximum kVp"
                            onChange={(e) => setMaxKvP(e.target.value)}
                            value={maxKvP}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <NewMDInput
                            sx={{ width: "100%" }}
                            variant="outlined"
                            label="Maximum mA"
                            onChange={(e) => setMaxMA(e.target.value)}
                            value={maxMA}
                          />
                        </Grid>
                      </Grid>

                      <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <MDButton
                          variant="outlined"
                          color="error"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </MDButton>
                        <MDBox sx={{ flex: "1 1 auto" }} />
                        {/* {isStepOptional(activeStep) && (
                          <MDButton color="warning" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                          </MDButton>
                        )} */}
                        <MDButton color="info" onClick={handleNext}>
                          Next
                        </MDButton>
                      </MDBox>
                    </>
                  );

                default:
                  return (
                    <>
                      <Grid container columnSpacing={2} rowSpacing={2} paddingY={3}>
                        <Grid display="flex" justifyContent="center" xs={12} md={6}>
                          {(() => {
                            switch (brand) {
                              case "Canon":
                                return <img width="30%" src={canon} alt={brand} />;

                              case "Siemens":
                                return <img width="40%" src={siemens} alt={brand} />;

                              case "GE":
                                return <img width="15%" src={ge} alt={brand} />;

                              case "Philips":
                                return <img width="40%" src={philips} alt={brand} />;

                              case "Shimadzu":
                                return <img width="40%" src={shimadzu} alt={brand} />;

                              case "Elekta":
                                return <img width="40%" src={elekta} alt={brand} />;

                              default:
                                return null;
                            }
                          })()}
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="h4">
                            {brand} {model}
                          </MDTypography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body">{modalityTypes}</MDTypography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body">
                            Tube (housing) number : {housingNum}
                          </MDTypography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body">
                            Tube (insert) number : {insertNum}
                          </MDTypography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body">Max kW : {maxKw}</MDTypography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body">Max kVp : {maxKvP}</MDTypography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <MDTypography variant="body">Max mA : {maxMA}</MDTypography>
                        </Grid>
                      </Grid>
                      <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <MDButton
                          variant="outlined"
                          color="error"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </MDButton>
                        <MDBox sx={{ flex: "1 1 auto" }} />
                        <MDButton
                          endIcon={
                            loading ? (
                              <CircularProgress disableShrink color="inherit" size="1rem" />
                            ) : null
                          }
                          color="success"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </>
                  );
              }
            })()}
          </MDBox>
        </Card>
      </Dialog>
      <MDBox py={3}>
        <Grid container justifyContent="center">
          <Grid xs={12} sm={8} md={6}>
            {(() => {
              if (newCustomerList[0]?.name === null) {
                return (
                  <MDBox>
                    <Card sx={{ py: "20px", px: "20px" }}>
                      <MDBox>
                        <MDBox display="flex" flexDirection="row" sx={{ mb: 2 }}>
                          <MDBox marginTop="3px">
                            <ReportProblemRoundedIcon color="error" sx={{ mr: 1 }} />
                          </MDBox>
                          <MDTypography variant="body1" sx={{ fontWeight: "bold" }}>
                            Account status : not affiliated
                          </MDTypography>
                        </MDBox>

                        <MDTypography variant="body2" sx={{ fontWeight: "regular", mb: 2 }}>
                          Hi {user?.displayName?.replace(/\s*\(.*?\)\s*/g, "")}, it seem&apos;s your
                          account is not affiliated, register your affiliation first before
                          proceeding to register the radiation devices and / or CME courses
                        </MDTypography>
                      </MDBox>
                      <MDButton
                        variant="outlined"
                        color="info"
                        sx={{ borderRadius: 10 }}
                        onClick={() => setDialogAffiliationOpen(true)}
                      >
                        Register my affiliation
                      </MDButton>
                    </Card>
                  </MDBox>
                );
              }

              if (
                newCustomerList[0]?.name !== null &&
                newCustomerList[0]?.metadata?.approval === "awaiting"
              ) {
                return (
                  <MDBox>
                    <Card sx={{ py: "20px", px: "20px" }}>
                      <MDBox>
                        <MDBox display="flex" flexDirection="row" sx={{ mb: 2 }}>
                          <MDBox marginTop="3px">
                            <ReportProblemRoundedIcon color="warning" sx={{ mr: 1 }} />
                          </MDBox>

                          <MDTypography variant="body1" sx={{ fontWeight: "bold" }}>
                            Account status : awaiting approval
                          </MDTypography>
                        </MDBox>

                        <MDTypography variant="body2" sx={{ fontWeight: "regular", mb: 2 }}>
                          Hi {user?.displayName?.replace(/\s*\(.*?\)\s*/g, "")}, you are one step
                          away from getting approved. Sit back and enjoy your ☕️ while awaiting our
                          system admin to review and approve your affiliation
                        </MDTypography>
                      </MDBox>
                    </Card>
                  </MDBox>
                );
              }

              if (
                machines.length === 0 &&
                newCustomerList[0]?.name !== null &&
                newCustomerList[0]?.metadata?.approval === "approved"
              ) {
                return (
                  <MDBox>
                    <Card sx={{ py: "20px", px: "20px" }}>
                      <MDBox>
                        <MDBox display="flex" flexDirection="row" sx={{ mb: 2 }}>
                          <MDBox marginTop="3px">
                            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
                          </MDBox>

                          <MDTypography variant="body1" sx={{ fontWeight: "bold" }}>
                            Account status : approved
                          </MDTypography>
                        </MDBox>

                        <MDTypography variant="body2" sx={{ fontWeight: "regular", mb: 2 }}>
                          Congratulation {user?.displayName?.replace(/\s*\(.*?\)\s*/g, "")}! Now you
                          can add your first radiation device by clicking the button below
                        </MDTypography>
                      </MDBox>

                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ borderRadius: 10 }}
                        onClick={() => setDialogRegisterOpen(true)}
                      >
                        <AddIcon />
                        Register my first radiation device
                      </MDButton>
                    </Card>
                  </MDBox>
                );
              }

              if (machines.length > 0) {
                return (
                  <MDBox>
                    <Card sx={{ py: "20px", px: "20px" }}>
                      <MDBox>
                        <MDBox display="flex" flexDirection="row" sx={{ mb: 2 }}>
                          <MDBox marginTop="3px">
                            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
                          </MDBox>

                          <MDTypography variant="body1" sx={{ fontWeight: "bold" }}>
                            Your machines
                          </MDTypography>
                        </MDBox>

                        <MDTypography variant="body2" sx={{ fontWeight: "regular", mb: 2 }}>
                          Number of machines registered : {machines.length}
                        </MDTypography>
                      </MDBox>
                    </Card>
                    <MDButton
                      sx={{ mt: 3 }}
                      variant="gradient"
                      color="success"
                      onClick={() => setDialogRegisterOpen(true)}
                    >
                      Add machine
                    </MDButton>
                  </MDBox>
                );
              }

              return (
                <Grid container justifyContent="center">
                  <Grid>
                    <CircularProgress color="inherit" />
                  </Grid>
                </Grid>
              );
            })()}
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer sx={{ position: "absolute", bottom: 0, height: "2.5rem", width: "100%" }} /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
